.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.wrapper-class {
	height : 35rem !important;
	color : black;
  border : 1px solid #888;
  overflow: hidden;
}

.classNametd {
  width : 10%;
}

.classTitletd {
  width : 25%;
  padding : 1rem;
}

.classContent {
  max-height : 11rem;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

.w-full input {
  width : 100%;  
}

.tdtextArea {
  width : 100%;
  border : 1px solid #888;
}